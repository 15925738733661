<template>
    <div v-if="availableAttributes">
        <div>
            <mercur-card class="packageset mx-2" ref="packageset">
                <div class="px-2">
                    <div class="row">
                        <div class="col-4">
                            <h4 class="mt-2 font-weight-normal">Attributes</h4>
                            <template v-if="Object.values(attributes).flat().length !== 0">
                                <template v-for="(selectedOptions, attributeKey) in attributes">
                                    <div data-test="productPackageAttributesList" v-if="selectedOptions.length" :key="attributeKey">
                                        <p class="mb-0"><strong>{{attributeKey | beautify}}</strong></p>
                                        <span :title="selectedOptions.join('\n')">{{selectedOptions.join(', ')}}</span>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div class="mt-2">
                                    <span>
                                        No attributes selected.<br /><template v-if="isAllowedToEdit">Please <a class="select-link" href="#" @click="openEdit('attributes')">select</a> them.</template>
                                    </span>
                                </div>
                            </template>
                        </div>
                        <div class="col-4">
                            <div v-if="facilities">
                                <h4 class="mt-2 font-weight-normal">Facilities</h4>
                                <template v-if="facilities.length && availableFacilities">
                                    <div data-test="productPackageFacilityList" :key="facilityKey" v-for="(facility, facilityKey) in facilities.map(facilityId => availableFacilities.find(facility => facility.locationId === facilityId))">
                                        <p class="mb-0"><strong>{{facility.locationName}}</strong></p>
                                        <span>{{facility | asAddress}}</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div>
                                        <span>
                                            No facilities selected.<br /><template v-if="isAllowedToEdit">Please <a class="select-link" href="#" @click="openEdit('facilities')">select</a> them.</template>
                                        </span>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col-4">
                            <h4 class="mt-2 font-weight-normal">Packages</h4>
                            <template v-if="configurations && configurations.length">
                                <div v-for="(packageConfiguration, key) in configurations" :key="key">
                                    <p data-test="productPackageConfigName" class="mb-0"><strong>{{packageConfiguration.packageName}}</strong></p>
                                    <span data-test="productPackageConfigurations">
                                        Dimensions: {{[packageConfiguration.packageWidth, packageConfiguration.packageLength, packageConfiguration.packageHeight].join('&times;')}}mm<br />
                                        Weight (tare): {{packageConfiguration.weightTare}}g<br/>
                                        Weight (net): {{packageConfiguration.weight}}<br />
                                        Weight multiplier (net): {{packageConfiguration.multiplier}}<br />
                                        Package type: {{packageConfiguration.packageType}}<br />
                                        Max units per package: {{packageConfiguration.maxUnitsPerPackage}}<br />
                                        <template v-if="packageConfiguration.packageType === 'ROLLABLE'">
                                            Max width: {{packageConfiguration.maxWidth}}mm<br />
                                        </template>
                                        <template v-if="packageConfiguration.packageType === 'NON_FOLDABLE' || packageConfiguration.packageType === 'FOLDABLE'">
                                            Max surface: {{packageConfiguration.maxSurface}}m2<br />
                                        </template>
                                    </span>
                                </div>
                            </template>
                            <template v-else>
                                <div>
                                    <span>
                                        No packages selected.<br />Please <a class="select-link" href="#" @click="openEdit('packages')">select</a> them.
                                    </span>
                                </div>
                            </template>

                        </div>
                        <div class="col-12" v-if="autoGenerated">
                            <h4 class="mt-0 mb-0 pl-15">This configuration was generated automatically.</h4>
                        </div>
                    </div>
                </div>

                <div v-if="isAllowedToEdit">
                    <div class="text-right">
                        <mercur-button data-test="productPackageEditButton" @click="editing = true" class="btn btn-icon btn-yellow">
                            <i class="fas fa-edit" />
                        </mercur-button>

                        <mercur-button data-test="productPackageDeleteButton" class="btn btn-icon btn-icon-sm btn-primary" @click="deleteConfirmationDialogActive = true">
                            <i class="fas fa-trash " />
                        </mercur-button>
                    </div>
                </div>
            </mercur-card>
        </div>
        <mercur-dialog :is-open.sync="deleteConfirmationDialogActive">
            <template #header><h3 class="font-weight-normal m-0">Are you sure you want to delete this package set?</h3></template>
            <p>Once you save the page, it is not possible to recover this setting. The package configuration will still be available.</p>
            <template #footer>
                <mercur-button class="btn" @click="deleteConfirmationDialogActive = false">Cancel</mercur-button>
                <mercur-button class="btn btn-red" data-test="productPackageDeleteModalDeleteButton" @click="deletePackageSet(index)">Delete</mercur-button>
            </template>
        </mercur-dialog>

        <mercur-dialog width="100%" class="edit-packageset" :is-open.sync="editing">
            <template #header><h3 class="font-weight-normal mt-0 mb-3">Edit packages</h3></template>

            <mercur-stepper :active-step.sync="activeStep">
                <mercur-step title="Attributes" id="attributes">
                    <div class="d-flex attribute-list">
                        <div class="col-3" data-test="productPackageModalAttributes" v-for="(attributeOptions, attributeKey) in availableAttributes" :key="attributeKey">
                            <attribute-block :bordered="false" :scrollable="true" class="set-attribute">
                                <template slot="header">
                                    <h3 class="attribute-list__title my-2">{{attributeKey | beautify}} <small data-test="productPackageModalAttributesSelectAll" class="select-all" @click="selectAllNone(attributeKey)">Select all/none</small></h3>
                                </template>
                                <div class="p-3 " v-for="(option, key) in attributeOptions" :key="key">
                                    <mercur-checkbox v-model="workingCopies.attributes[attributeKey]" :value="option.option" @change="$v.workingCopies.attributes.$touch()">
                                        <span>{{option.option | beautify}}</span>
                                    </mercur-checkbox>
                                </div>
                            </attribute-block>
                        </div>
                    </div>
                </mercur-step>

                <mercur-step title="Facilities" id="facilities" disabled>
                    <div class="d-flex attribute-list">
                        <div class="col-12">
                            <attribute-block :bordered="false" :scrollable="true">
                                <template slot="header"><h3 class="attribute-list__title my-2">Facilities</h3></template>
                                <div data-test="productPackageModalFacilities" v-for="(availableFacility, facilityKey) in availableFacilities" :key="facilityKey">
                                    <label class="d-flex p-3 align-items-center">
                                        <mercur-checkbox v-model="workingCopies.facilities" :value="availableFacility.locationId" @change="$v.workingCopies.facilities.$touch()">
                                        </mercur-checkbox>
                                        <div>
                                            <strong>{{availableFacility.locationName}}</strong><br>
                                            <span>{{availableFacility | asAddress}}</span>
                                        </div>
                                    </label>
                                </div>
                            </attribute-block>
                        </div>
                    </div>
                </mercur-step>

                <mercur-step title="Packages" id="configurations" disabled>
                    <div class="d-flex row">
                        <div class="col-6">
                            <h5 class="mt-1 mb-2 font-weight-normal text-grey">Available packages</h5>
                            <div class="packages-scroller" >
                                <div data-test="productPackageModalAvailablePackages" v-for="availablePackage in filteredAvailablePackages" :key="availablePackage.packageId">
                                    <package-configuration :is-edit="false" :package-configuration="availablePackage">
                                        <template slot="content">
                                            <div class="text-right">
                                                <mercur-button data-test="productPackageModalAddPackageButton" class="btn btn-icon btn-yellow btn-icon-sm" @click="addPackage(availablePackage)">
                                                    <i class="fas fa-angle-right" />
                                                </mercur-button>
                                            </div>
                                        </template>
                                    </package-configuration>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <h5 class="mt-1 mb-2 font-weight-normal text-grey">Selected packages</h5>
                            <div class="packages-scroller">
                                <div data-test="productPackageModalSelectedPackages" v-for="(pack, key) in workingCopies.configurations" :key="pack.packageId">
                                    <package-configuration
                                        :is-edit="true"
                                        :package-configuration="pack"
                                        :$v="$v.workingCopies.configurations.$each[key]"
                                    >
                                        <template slot="action">
                                             <div class="text-right">
                                                <mercur-button class="btn btn-icon btn-red btn-icon-sm" @click="workingCopies.configurations.splice(key, 1)">
                                                    <i class="fas fa-times" />
                                                </mercur-button>
                                             </div>
                                        </template>
                                    </package-configuration>
                                </div>
                                <template v-if="!workingCopies.configurations.length">
                                    No packages selected
                                </template>
                            </div>
                        </div>
                    </div>
                </mercur-step>
            </mercur-stepper>

            <template slot="footer">
                <mercur-button class="btn" @click="editing = false">Discard changes</mercur-button>
                <mercur-button data-test="productPackageEditNextStepButton" :disabled="this.$v.workingCopies[this.activeStep].$invalid" class="btn btn-yellow" @click="nextStep">
                    <span v-if="activeStep === 'configurations'">
                        Continue
                    </span>
                    <span v-else>
                        Next step
                    </span>
                </mercur-button>
            </template>
        </mercur-dialog>
    </div>
</template>

<script>
import AttributeBlock from '@/components/AttributeBlock'
import collect from 'collect.js'
import CONFIG from '@root/config'
import PackageConfiguration from '@/components/packages/PackageConfiguration'
import { validationMixin } from 'vuelidate'
import {
    required,
    minLength,
} from 'vuelidate/lib/validators'

export default {
    name: 'ProductPackageSet',
    props: ['attributes', 'configurations', 'facilities', 'index', 'editOnCreate', 'availableAttributes', 'availablePackages', 'availableFacilities', 'autoGenerated'],
    components: { AttributeBlock, PackageConfiguration },
    mixins: [validationMixin],
    validations () {
        return {
            workingCopies: {
                attributes: {
                    minLength: model => Object.values(model).some(attribute => attribute.length),
                },
                facilities: {
                    required,
                    minLength: minLength(1),
                },
                configurations: {
                    minLength: minLength(1),
                    required,
                    $each: CONFIG.VALIDATIONS.PACKAGES.PACKAGE_CONFIGURATION,
                },
            },
        }
    },
    data () {
        return {
            deleteConfirmationDialogActive: false,
            editing: false,
            workingCopies: {
                attributes: {},
                facilities: {},
                configurations: {},
            },
            selectPackagesDialog: false,
            activeStep: 'attributes',
            nextStepDisabled: false,
            packageTypes: CONFIG.PACKAGES.TYPES,
        }
    },

    computed: {
        isAllowedToEdit () {
            return this.isAllowedTo('SupplierCentral/updateProductConfigurationPackages')
        },
        filteredAvailablePackages () {
            return this.availablePackages
        },
    },

    methods: {
        selectAllNone (attributeKey) {
            const allAttributeValues = this.availableAttributes[attributeKey]
            if (this.$v.workingCopies.attributes.$model[attributeKey].length < allAttributeValues.length) {
                this.$v.workingCopies.attributes.$model[attributeKey] = [...allAttributeValues.map(attribute => attribute.option)]

                return
            }
            this.$v.workingCopies.attributes.$model[attributeKey] = []
        },
        addPackage (availablePackage) {
            const newPackage = {
                ...availablePackage,
                packageType: null,
                maxWidth: null,
                weight: null,
                maxUnitsPerPackage: null,
                maxSurface: null,
                multiplier: 1,
            }
            this.$v.workingCopies.configurations.$model.unshift(newPackage)
            this.$v.workingCopies.configurations.$touch()
        },
        openEdit () {
            this.editing = true
        },
        nextStep () {
            this.$v.workingCopies[this.activeStep].$touch()
            switch (this.activeStep) {
            case 'attributes':
                this.activeStep = 'facilities'
                break

            case 'facilities':
                this.activeStep = 'configurations'
                break

            case 'configurations':
                this.save()
                this.activeStep = 'facilities'
                break
            }
        },
        save () {
            const savedData = JSON.parse(JSON.stringify(this.workingCopies))
            savedData.index = this.index
            this.$emit('save', savedData)
            this.editing = false

            const packageSetClasslist = this.$refs.packageset.$el.classList
            packageSetClasslist.add('packageset--is-updated')
            setTimeout(() => {
                packageSetClasslist.remove('packageset--is-updated')
            }, 100)
        },
        deletePackageSet (index) {
            this.$emit('delete', { index })
            this.deleteConfirmationDialogActive = false
        },
    },
    watch: {
        editing (editing) {
            if (!editing) {
                return
            }

            const currentAttributes = JSON.parse(JSON.stringify(this.attributes))
            const workingAttributes = collect(Object.entries(this.availableAttributes)).mapWithKeys(attribute => {
                return [attribute[0], (currentAttributes[attribute[0]] || [])]
            }).all()
            this.workingCopies = Object.assign({}, {
                index: this.index,
                attributes: workingAttributes,
                configurations: JSON.parse(JSON.stringify(this.configurations)),
                facilities: JSON.parse(JSON.stringify(this.facilities)),
            })
        },
        editOnCreate (value) {
            if (value) {
                this.editing = true
            }
        },
    },
    created () {
        if (this.editOnCreate) {
            this.editing = true
        }
    },
}
</script>

<style scoped lang="scss">
    .edit-packageset {
        /deep/ .dialog__wrapper {
            min-height: 90%;
        }
    }

    .packages-scroller {
        flex: 0 1 100%;
        max-height: 60vh;
        overflow-y: auto;
        padding: 7px 7px 7px 3px;
    }

    .packageset {
        margin-bottom: 10px;
        transition: background-color 1.5s ease-out;

        &--is-updated {
            background-color: #fbe38f;
            transition: background-color 10ms;
        }
    }

    .attribute {
        padding-left: 4px;
        padding-right: 4px;
    }
    .set-attribute {
        height: 100%;
    }

    .attribute-list {
        z-index: 30;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding: 10px;
        &__title {
            color: #fff;
        }
    }

    .speed-dial {
        position: absolute;
        bottom: 13px;
        z-index: 4;
        right: 40px;
        &__target {
            margin-right: 10px;
        }

        &__content {
            right: 5px;
        }
    }

    .package-selection {
        z-index: 30;
        width: 600px;
    }

    .select-link {
        text-decoration: underline;
        font-weight: 600;
    }
    .select-all {
        font-size: 10px;
        display: block;
        cursor: pointer;
        letter-spacing: -0.1px;
    }
</style>
